// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.jsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react";
import stylesheet from "~/tailwind.css";
import { json } from "@remix-run/node";
import { storyblokInit, apiPlugin, getStoryblokApi } from "@storyblok/react";
import Header from "./components/Header";
import Feature from "./components/Feature";
import Grid from "./components/Grid";
import Page from "./components/Page";
import Teaser from "./components/Teaser";
import Layout from "./components/Layout";
import Hero from "./components/Hero";
import Article from "./components/Article";
import AllArticles from "./components/AllArticles";
import PopularArticles from "./components/PopularArticles";
import { isPreview } from "~/utils/isPreview";
import HeroSection from "./components/HeroSection";
import StatisticsSection from "./components/StatisticsSection";
import HighlightCard from "./components/HighlightCard";
import HighlightSection from "./components/HighlightSection";
import HighlightList from "./components/HighlightList";
import HighlightItem from "./components/HighlightItem";
import TestimonialSection from "./components/TestimonialSection";
import SolutionsOverview from "./components/SolutionsOverview";
import ServiceCard from "./components/ServiceCard";
import MissionStatement from "./components/MissionStatement";
import Servicecardlist from "./components/ServicecardList";
const components = {
  'horizontal-header': Header,
  herosection: HeroSection,
  statisticssection: StatisticsSection,
  highlightsection: HighlightSection,
  highlightcard: HighlightCard,
  highlightlist: HighlightList,
  highlightitem: HighlightItem,
  SolutionsOverview,
  ServiceCard,
  MissionStatement: MissionStatement,
  Servicecardlist,
  TestimonialSection: TestimonialSection,
  feature: Feature,
  grid: Grid,
  teaser: Teaser,
  page: Page,
  hero: Hero,
  article: Article,
  "all-articles": AllArticles,
  "popular-articles": PopularArticles
};
const isServer = typeof window === "undefined";
const accessToken = isServer ? process.env.STORYBLOK_TOKEN : window.env.STORYBLOK_TOKEN;
storyblokInit({
  accessToken,
  use: [apiPlugin],
  components,
  bridge: isPreview()
});
export const loader = async ({
  params
}) => {
  let lang = params['*'] || "en";
  lang = lang.substr(0, 2);

  //console.log('params:',params);
  //console.log('lang:',lang);

  const {
    data
  } = await getStoryblokApi().get("cdn/stories/finexus-group-website/header-menu", {
    version: "draft",
    resolve_relations: "default",
    language: lang
  }).catch(e => {
    console.log("e", e);
    return {
      data: null
    };
  });
  return json({
    lang,
    env: {
      STORYBLOK_TOKEN: process.env.STORYBLOK_TOKEN,
      STORYBLOK_IS_PREVIEW: process.env.STORYBLOK_IS_PREVIEW
    },
    header: data.story.content
  });
};
export const links = () => [{
  rel: "stylesheet",
  href: stylesheet
}];
export default function App() {
  _s();
  const {
    lang,
    env,
    header
  } = useLoaderData();
  return <html lang={lang}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <title>{header.site_title}</title>
        <link rel="icon" href={header.site_icon_150.filename} sizes="32x32" />
        <link rel="icon" href={header.site_icon_300.filename} sizes="192x192" />
        <link rel="apple-touch-icon" href={header.site_icon_300.filename} />
        <meta name="msapplication-TileImage" content={header.site_icon_300.filename} />
        <Meta />
        <Links />
      </head>
      <body>
        <Layout>
          <Outlet />
          <ScrollRestoration />
          <Scripts />
          <LiveReload />
        </Layout>
        <script dangerouslySetInnerHTML={{
        __html: `window.env = ${JSON.stringify(env)}`
      }} />

        <script src="//app.storyblok.com/f/storyblok-v2-latest.js" type="text/javascript">
        </script>
      </body>
    </html>;
}
_s(App, "ZI8zv1JoOHozMkMwfKiINTxZ7qw=", false, function () {
  return [useLoaderData];
});
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;