// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/StatisticsSection.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/StatisticsSection.jsx");
  import.meta.hot.lastModified = "1721878890509.7322";
}
// REMIX HMR END

import { storyblokEditable } from "@storyblok/react";
const StatisticsSection = ({
  blok
}) => {
  //console.log(blok);
  return <section className="bg-blue-900 text-white py-12">
       <div {...storyblokEditable(blok)} className="container mx-auto grid grid-cols-2 md:grid-cols-5 gap-8 text-center">
        {blok.statisticitems.map((item, i) => {
        return <div key={i}>
            <h2 className="text-3xl font-bold">{item.title}</h2>
            <p className="text-5xl">{item.value}</p>
          </div>;
      })}
    </div>
    </section>;
};
_c = StatisticsSection;
export default StatisticsSection;
var _c;
$RefreshReg$(_c, "StatisticsSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;