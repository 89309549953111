// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer.jsx");
  import.meta.hot.lastModified = "1721977392813.1223";
}
// REMIX HMR END

const Footer = () => <div className="bg-gray-100">
      <div className="container mx-auto py-10">
        <div className="flex flex-wrap gap-10 justify-between">
          <div className="w-full md:w-auto">
            <div className="mb-5">
              <p className="text-lg font-bold text-blue-900">Who We Are</p>
              <ul className="mt-2 space-y-2">
                <li><a href="#" className="text-gray-700">About Us</a></li>
                <li><a href="#" className="text-gray-700">Licenses & Accreditations</a></li>
                <li><a href="#" className="text-gray-700">Newsroom</a></li>
                <li><a href="#" className="text-gray-700">Careers</a></li>
                <li><a href="#" className="text-gray-700">Contact</a></li>
              </ul>
            </div>
            <div className="mb-5">
              <p className="text-lg font-bold text-blue-900">Legal</p>
              <ul className="mt-2 space-y-2">
                <li><a href="#" className="text-gray-700">Privacy Policy</a></li>
                <li><a href="#" className="text-gray-700">Disclaimer</a></li>
              </ul>
            </div>
            <div className="mb-5">
              <p className="text-lg font-bold text-blue-900">Affiliate Sites</p>
              <ul className="mt-2 space-y-2">
                <li><a href="#" className="text-gray-700">Finexus Card</a></li>
                <li><a href="#" className="text-gray-700">Fintani</a></li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-auto">
            <div className="mb-5">
              <p className="text-lg font-bold text-blue-900">AREMA Platform</p>
              <ul className="mt-2 space-y-2">
                <li><a href="#" className="text-gray-700">LHDN e-Invoicing</a></li>
              </ul>
            </div>
            <div className="mb-5">
              <p className="text-lg font-bold text-blue-900">MyXaaS Platform</p>
              <ul className="mt-2 space-y-2">
                <li><a href="#" className="text-gray-700">About MyXaaS</a></li>
                <li><a href="#" className="text-gray-700">Sandbox</a></li>
                <li><a href="#" className="text-gray-700">Demo</a></li>
                <li><a href="#" className="text-gray-700">B40 Application</a></li>
                <li><a href="#" className="text-gray-700">Loan Application</a></li>
                <li><a href="#" className="text-gray-700">University Application</a></li>
                <li><a href="#" className="text-gray-700">Online Marketplace Application</a></li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-auto">
            <div className="mb-5">
              <p className="text-lg font-bold text-blue-900">Solutions</p>
              <ul className="mt-2 space-y-2">
                <li><a href="#" className="text-gray-700">Merchants</a></li>
                <li><a href="#" className="text-gray-700">Management Portal</a></li>
                <li><a href="#" className="text-gray-700">Payment Gateway</a></li>
                <li><a href="#" className="text-gray-700">POS System</a></li>
                <li><a href="#" className="text-gray-700">eCommerce</a></li>
                <li><a href="#" className="text-gray-700">Financial Institution</a></li>
                <li><a href="#" className="text-gray-700">ITO</a></li>
                <li><a href="#" className="text-gray-700">BPO</a></li>
                <li><a href="#" className="text-gray-700">Cheque System</a></li>
                <li><a href="#" className="text-gray-700">Banking System</a></li>
                <li><a href="#" className="text-gray-700">Loan System</a></li>
                <li><a href="#" className="text-gray-700">RegTech</a></li>
              </ul>
            </div>
            <div className="mb-5">
              <p className="text-lg font-bold text-blue-900">Enterprises</p>
              <ul className="mt-2 space-y-2">
                <li><a href="#" className="text-gray-700">AIV</a></li>
                <li><a href="#" className="text-gray-700">ACDD</a></li>
                <li><a href="#" className="text-gray-700">Prepaid Card eWallet</a></li>
                <li><a href="#" className="text-gray-700">360° Payment</a></li>
                <li><a href="#" className="text-gray-700">Partners</a></li>
                <li><a href="#" className="text-gray-700">POS Agent</a></li>
                <li><a href="#" className="text-gray-700">Master Recruitment Agent</a></li>
                <li><a href="#" className="text-gray-700">Third-Party Acquirer</a></li>
                <li><a href="#" className="text-gray-700">Co-issued Card/eWallet</a></li>
                <li><a href="#" className="text-gray-700">White-labeled Card/eWallet</a></li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-auto">
            <div className="mb-5">
              <p className="text-lg font-bold text-blue-900">Consumers</p>
              <ul className="mt-2 space-y-2">
                <li><a href="#" className="text-gray-700">eWallet & Card</a></li>
                <li><a href="#" className="text-gray-700">Personal Loan</a></li>
              </ul>
            </div>
          </div>
        </div>
        
      </div>
      <div className="bg-white py-4">
        <div className="container mx-auto flex justify-between items-center">
          <p className="text-gray-700 text-sm">© 2024 FINEXUS. All rights reserved.</p>
          <div className="flex items-center gap-2">
            <p className="text-gray-700 text-sm">Total Visitors:</p>
            <p className="text-gray-700 text-sm">146,583</p>
          </div>
        </div>
      </div>
    </div>;
_c = Footer;
export default Footer;
var _c;
$RefreshReg$(_c, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;