// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/TestimonialSection.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/TestimonialSection.jsx");
}
// REMIX HMR END

import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css?__remix_sideEffect__";
const TestimonialSection = ({
  blok
}) => {
  return <Swiper spaceBetween={50} slidesPerView={1}>
      <SwiperSlide>
        <div className="bg-gray-400 h-64 flex items-center justify-center">
          Slide 1
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="bg-gray-400 h-64 flex items-center justify-center">
          Slide 2
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="bg-gray-400 h-64 flex items-center justify-center">
          Slide 3
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="bg-gray-400 h-64 flex items-center justify-center">
          Slide 4
        </div>
      </SwiperSlide>
    </Swiper>;
};
_c = TestimonialSection;
export default TestimonialSection;
var _c;
$RefreshReg$(_c, "TestimonialSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;