// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HighlightSection.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HighlightSection.jsx");
  import.meta.hot.lastModified = "1722233511556.9558";
}
// REMIX HMR END

import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
const HighlightSection = ({
  blok
}) => {
  //console.log(blok);
  return <section className="py-16" {...storyblokEditable(blok)}>
      <div className="container mx-auto">
        <h2 className="text-2xl font-bold text-left mb-12">{blok.title}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {blok.highlights.map(nestedBlok => <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />)}
        </div>
        <div className="text-right mt-12">
          <a href={blok.newsroom_link} className="text-blue-600 hover:underline">{blok.newsroom_link_text}</a>
        </div>
      </div>
    </section>;
};
_c = HighlightSection;
export default HighlightSection;
var _c;
$RefreshReg$(_c, "HighlightSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;