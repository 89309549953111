// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HighlightCard.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HighlightCard.jsx");
  import.meta.hot.lastModified = "1721976437455.061";
}
// REMIX HMR END

import { storyblokEditable } from "@storyblok/react";
const HighlightCard = ({
  blok
}) => {
  //console.log('highlightcard:',blok);
  return <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300" {...storyblokEditable(blok)}>
      <img src={blok.image.filename} alt={blok.title} className="w-full h-40 object-cover rounded mb-4" />
      <h3 className="text-xl font-bold mb-2 text-left">{blok.title}</h3>
      <p className="text-gray-700 mb-4 text-left">{blok.date}</p>
      <p className="text-gray-600 mb-4 text-left">{blok.description}</p>
      <div className="flex items-center text-blue-600">
        <a href={blok.morelink} className="hover:underline text-sm mr-1">{blok.morelabel}</a>
        <span className="text-sm">→</span>
      </div>
    </div>;
};
_c = HighlightCard;
export default HighlightCard;
var _c;
$RefreshReg$(_c, "HighlightCard");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;