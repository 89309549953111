// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HighlightList.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HighlightList.jsx");
  import.meta.hot.lastModified = "1722220952876.26";
}
// REMIX HMR END

import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
const HighlightList = ({
  blok
}) => {
  return <div className="flex flex-col space-y-0">
      {blok.items.map(nestedBlok => {
      //console.log('nestedBlok:',nestedBlok);
      return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />;
    })}
    </div>;
};
_c = HighlightList;
export default HighlightList;
var _c;
$RefreshReg$(_c, "HighlightList");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;