// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ServicecardList.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ServicecardList.jsx");
  import.meta.hot.lastModified = "1722238639328.9587";
}
// REMIX HMR END

import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
const Servicecardlist = ({
  blok
}) => {
  //console.log('SCL:',blok);
  return <div className="flex flex-col space-y-0">
      {blok.servicecards.map(nestedBlok => {
      //console.log('nestedBlok:',nestedBlok);
      return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />;
    })}
    </div>;
};
_c = Servicecardlist;
export default Servicecardlist;
var _c;
$RefreshReg$(_c, "Servicecardlist");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;