// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/SolutionsOverview.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/SolutionsOverview.jsx");
  import.meta.hot.lastModified = "1722240027725.9165";
}
// REMIX HMR END

import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
const SolutionsOverview = ({
  blok
}) => {
  return <section className="py-16">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {blok.solutions.map(nestedBlok => {
          //console.log("SO:",nestedBlok);
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />;
        })}
        </div>
       </div> 
    </section>;
};
_c = SolutionsOverview;
export default SolutionsOverview;
var _c;
$RefreshReg$(_c, "SolutionsOverview");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;